<template>
  <div>
    <ExplorerStatus :explorer-name="row.c_name"/>
  </div>
</template>
<script>
import ExplorerStatus from '@/components/specific/ExplorerStatus';

export default {
  components: {ExplorerStatus},
  props: {
    row: Object,
  },
};
</script>

<style scoped>

</style>